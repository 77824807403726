.results-page { 
	background-color: #ffffff;
	border: 1px solid Navy; 
	padding-bottom: 16px;
	max-width: 600px;
	margin: 0 auto;

	a {
		text-decoration: none;
	}

	h3 {
		text-align: center;
		color: #0080ff;
	}

	h3.left {
		text-align: left;
		padding-left: 16px;
	}

	img {
		display: block;
		margin: 0 auto;
	}

	p {
		padding: 0px 16px;
	}

	ul {
		margin: 0px 16px;
	}

	.one {
    	background-color: #e6e8ff;
	}
}

.event-header {
	img {
		display: block;
		margin: 0 auto;
	}

	h1 {
		color: #0054A8;
		text-align: center;
		font-size: 25px;
	}
}

.schedule-table {
	width: 100%;

	th {
		text-align: left;
		background-color: #ffffcc;
		border-bottom: 1px solid black;
	}

	td {
		padding: 6px;
	}
}

.men {
	background-color : #99CCFF;
}	 

.wom { 
	background-color: #ffb6c1;
}

.wom, .men {
	border-top: 1px solid black;
   	border-bottom: 1px solid black;
   	padding: 3px;
   	margin: 16px 2px;
   	color: #000000;
	font-family: Arial, Helvetica, sans-serif;
	text-align: center;
	font-size: 20px;
	vertical-align: middle;
}

.pdf, .fx, .ph, .sr, .vt, .pb, .hb, .ub, .bb {
	display: block;
	margin: 4px 0;
	padding-left: 48px;
    padding-right: 16px;

	&::before {
    	content: " ";
    	display: inline-block;
    	width: 16px;
    	height: 16px;
    	margin: 0 16px 0 -32px;
    	vertical-align: bottom;
	}
}

.pdf::before {
    background-image: url(/assets/images/ar.gif);
}

.fx::before {
	background-image: url(/assets/images/icons/1fx15x15.gif);
}

.ph::before {
	background-image: url(/assets/images/icons/2ph15x15.gif);
}

.sr::before {
	background-image: url(/assets/images/icons/3sr15x15.gif);
}

.vt::before {
	background-image: url(/assets/images/icons/4vt15x15.gif);
}

.pb::before {
	background-image: url(/assets/images/icons/5pb15x15.gif);
}

.hb::before {
	background-image: url(/assets/images/icons/6hb15x15.gif);
}

.ub::before {
	background-image: url(/assets/images/icons/2ub15x15.gif);
}

.bb::before {
	background-image: url(/assets/images/icons/3bb15x15.gif);
}
