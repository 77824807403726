.media-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    
    @media (min-width: 500px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.media-grid-item {
    padding: 1rem;
    text-align: center;

    a {
        font-size: 0.75rem;
    }
}

.media-link {
    font-size: 0.875rem;
}