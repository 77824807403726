* {
	box-sizing: border-box;
}

body {
	background: url('/assets/images/bg4.gif');
    font-size: 16px;
	color: #151515;
	font-family: Verdana, Arial, sans-serif;
	font-weight: normal;
	margin: 0px;
}

a {
	color: #025CC9;
}

.content {
	min-height: calc(100vh - 80px);
	margin: 0 auto;
	max-width: 800px;
	padding: 16px;
}

.rounded {
	background-color: #ffffff;
	border: 1px solid #84aae7;
	border-radius: 10px;
	padding: 16px;
	margin-bottom: 16px;
	max-width: 800px;
}

#intro {
	display: none;
	min-height: 160px;
}

.globe {
	background-image: url('/assets/images/flagglobe.gif');
	width: 160px;
	height: 160px;
	text-align: center;
	padding-top: 20px;
	display: inline-block;
  	float: left;
  	margin: 0 20px 0 0;

	img {
		height: 120px;
		width: 120px;
	}
}

table.results-list {
	border: 2px solid #84aae7;
	background-color: #ffffff;
	margin: 0 0 16px 0;
	width: 100%;
	max-width: 800px;

	a {
		color: black;

		&:hover {
			color: #025CC9;
		}
	}

	th {
		text-align: left;
		padding: 2px;
		background-color: #ffffcc;
		border-bottom: 1px solid black;
		color: #DE3163;
		font-family: Comic Sans MS, Verdana, sans-serif;

		h2 {
			margin: 0;
		}
	}

	tr:nth-child(odd) {
		background: #f5f5f5;
	}

	td, th {
		vertical-align: top;
		padding: 4px 8px;
	}

	tr td:first-child {
		white-space: nowrap;
	}
}

.table-scroll {
	overflow-x: auto;
}

.team {
	background: #FFFF99 !important; 
	
	td {
		font-weight: 700;
	}
}

.hide-small {
	display: none;
}

#featured {
	font-size: 14px;

	a {
		text-decoration: none;
	}

	li {
		line-height: 20px;
	}
}

.country-list-item {
	width: 40px;
	height: 45px;
	text-align: center;
	display: inline-block;
}

.year-list-item {
	padding: 6px;
	margin-bottom: 8px;
	border: 1px solid #84aae7;
	border-radius: 5px;
	text-align: center;
	display: inline-block;
	width: 70px;
	background-color: #e6faff;
	transition: background-color 0.5s;

	&:hover {
		background-color: #ffccff;
	}
}

.podium-list {
	padding-left: 0px;
	list-style: none;
}

h1 {
	color: #DE3163;
	font-weight: bold;
	font-size: 20px;
}

h1.results {
	color: #0054A8;
	font-size: 25px;
	padding-left: 16px;
}

h2 {
	color: #DE3163;
	font-weight: bold;
	font-size: 16px;
	font-family: Comic Sans MS;
	margin-top: 0;
}

img {
	max-width: 100%;
}

img.country-flag {
	display: block;
	margin: 0 auto;
}

ul {
	padding-left: 16px;
}

.inline {
	display: inline-block;
	margin: 0;
}

footer {
	background-color: #9fb7d7;
	height: 26px;
	padding-left: 16px;
	padding-top: 4px;
	text-align: center;
}

.home-link {
	display: none;
}

@media only screen and (min-width: 768px) {
	#intro {
		display: block;
	}

	.home-link {
		display: inline;
	}

	.hide-small {
		display: initial;
	}

	img.country-flag {
		display: initial;
		float: left;
		margin: 12px 20px 0 0;
	}

	.podium-list {
		padding-left: 16px;
	}
}

@import 'results';
@import 'navigation';
@import 'media';
