header {
	background-color: #ffffff;
	background-image: linear-gradient(to bottom, #ffffff, #e2e2e2);
	height: 40px;
	font-weight: bold;
	font-family: Rockwell, Verdana, sans-serif;
	font-size: 24px;
	padding-top: 10px;
	padding-left: 16px;

	a, button {
		text-decoration: none;
		color: #2f2f2f;
    }

    button {
        background: transparent;
        font-weight: bold;
        font-family: Rockwell, Verdana, sans-serif;
        font-size: 24px;
        border: 0;
        padding-right: 20px;
        cursor: pointer;
    }

    #menu-open, #menu-close {
        display: inline-block;
        width: 13px;
        margin-left: 5px;
    }

    #menu-close {
        display: none;
    }

    nav {
        text-align: right;
        position: absolute;
        top: 10px;
        right: 0;

        ul {
            background-color: white;
            margin: 0;
            padding: 0;
            border-bottom: 2px solid #e2e2e2;
            text-align: center;
            width: 100vw;
            box-shadow: 5px 5px 10px #e2e2e2;
        }

        li {
            list-style: none;
            padding: 15px 20px 10px 20px;
            border-bottom: 1px solid #e2e2e2;
        }

        ul li:hover {
            color: white;
            background-color:#e2e2e2;
        }
    
        a {
            display: block;
        }
    }
    
    ul {
        display: none;
    }
      
    ul.is-active {
        display: block;
    }

    #site-logo {
        display: none;
    }
      
    @media (min-width: 500px) {
        #site-logo {
            display: inline-block;
        }
    
        #home {
            display: none;
        }
    }
}